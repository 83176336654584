<template>
    <v-container>
        <v-row align="top" justify="center" class="mt-14">
            <v-col cols="6">
                <v-card style="min-height:355px;">
                    <v-card-text>
                        <div class="text-h4 mt-5 mb-5">
                            Begin your HAM license training today. Track your progress in each of the 8 sections with practice tests.<br /><br />Start today, it's free.
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6">
                <Login />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// import { commentsCollection } from '@/firebase'
// Photo by <a href="https://unsplash.com/@nittygritty_photo?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Fringer Cat</a> on <a href="/s/photos/radio?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>


export default {
    components: {
        Login: () => import ( /* webpackChunkName: "Exam" */ "@/views/Login.vue"),
    },
    data() {
        return {
            loading: false,
            selection: 1,
        };
    },
    computed: {},
    methods: {},
    filters: {},
};
</script>

<style lang="scss" scoped>

</style>