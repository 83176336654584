<template>
  <v-card outlined >
    <v-toolbar color="primary" dark flat  ><v-toolbar-title>Review test</v-toolbar-title></v-toolbar>
    <v-card-text style="height:500px;">
      <v-list-item three-line v-for="(question,index) in exam.exam" :key="index">
      <v-list-item-content>
        <v-row>
            <v-col>
                <div class="body-1 ">
                    {{ index + 1 }} ) {{question.questionenglish}}
                </div>
            </v-col>
            <v-col cols="3">
                <div class="text-right body-1 text--secondary">{{question.questionid}}</div>
            </v-col>
        </v-row>
        
        <v-radio-group class="ml-3 mt-n2">
          <v-radio class="correct">
            <template v-slot:label>
              {{question.correctanswerenglish}}
              <v-icon right v-if="exam.answers[question.questionid] === question.correctanswerenglish ">
                  mdi-check
              </v-icon>
            </template>
          </v-radio>
          <v-radio>
            <template v-slot:label>
              {{question.incorrectanswer1english}}
              <v-icon right v-if="exam.answers[question.questionid] === question.incorrectanswer1english ">
                  mdi-close
              </v-icon>
            </template>
          </v-radio>
          <v-radio>
            <template v-slot:label>
              {{question.incorrectanswer2english}}
              <v-icon right v-if="exam.answers[question.questionid] === question.incorrectanswer2english ">
                  mdi-close
              </v-icon>
            </template>
          </v-radio>
          <v-radio>
            <template v-slot:label>
              {{question.incorrectanswer3english}}
              <v-icon right v-if="exam.answers[question.questionid] === question.incorrectanswer3english ">
                  mdi-close
              </v-icon>
            </template>
          </v-radio>
        </v-radio-group>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
    <v-divider></v-divider>
   <v-card-actions>
     <span class=" body-1 text--disabled" v-if="submitted">Correct {{ answeredCorrect }}/{{examQuestionCount}}</span>
      <v-spacer />
      <button @click="$emit('close')" class="button">Close</button>
    </v-card-actions>
  </v-card>
</template>
<script>
// import { commentsCollection, postsCollection, auth } from '@/firebase'

export default {
  props: ['exam'],
  data() {
    return {
      enabled: true,
      showCorrect: true,
      submitted: true,
    }
  },
  computed: {
    examQuestionCount: function() {
        return this.exam.exam.length
    },
    answeredCorrect: function() {
        // Score answers
        var count = 0
        var questionKeys = Object.keys(this.exam.results)
          questionKeys.forEach(qkey => {
            count = count + this.exam.results[qkey]
          })
        return count
    },

	},
  methods: {
  }
}
</script>

<style scoped>
  .correct{
    border: 1px solid green;
    border-radius: 4px;
  }
</style>