<template>
    <v-card outlined>
        <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Practice test</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="examLoaded" style="height:500px;">
            <v-list-item three-line v-for="(question,index) in exam" :key="index + 'a'">
                <v-list-item-content>
                    <v-row>
                        <v-col>
                            <div class="body-1 ">
                                {{ index + 1 }} ) {{question.questionenglish}}
                            </div>
                        </v-col>
                        <v-col cols="3">
                            <div class="text-right body-1 text--secondary">{{question.questionid}}</div>
                        </v-col>
                    </v-row>
                    <v-radio-group v-model="answers[question.questionid]" class="ml-3 mt-n2">
                        <v-radio :value="q" v-for="(q, indx) in question.choicesEng" :key="indx + 'b'" :disabled="!enabled" :class="{ correct: question.correctanswerenglish == q && showCorrect }">
                            <template v-slot:label>
                                {{q}}
                                <v-icon dark right v-if="answers[question.questionid] == question.correctanswerenglish &&  answers[question.questionid] == q && showCorrect">
                                    mdi-checkbox-marked-circle
                                </v-icon>
                            </template>
                        </v-radio>
                    </v-radio-group>

                   

                </v-list-item-content>
            </v-list-item>
        </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="d-flex justify-space-between">
        <v-btn
        color="primary"
        text @click="close()"  >Close</v-btn>
      
      <span class=" body-1 text--disabled" v-if="!submitted">Answered {{ answerCount }}/{{examQuestionCount}}</span>
      <span class=" body-1 text--disabled" v-if="submitted">Correct {{ answeredCorrect }}/{{examQuestionCount}}</span>
      <v-btn
        color="primary"
        outlined @click="submit()" v-if="!submitted" :disabled="!submitEnabled" >Submit exam</v-btn>
      
    </v-card-actions>
  </v-card>
</template>

<script>
// import { commentsCollection, postsCollection, auth } from '@/firebase'
import { mapState } from 'vuex'

export default {
    data() {
        return {
            answers: {},
            results: {},
            enabled: true,
            showCorrect: false,
            submitted: false,
            answeredCorrect: 0
        }
    },
    computed: {
      ...mapState(['userProfile']),
        examLoaded: function() {
            return this.$store.state.exam.length > 0;
        },
        exam: function() {
            return this.$store.state.exam;
        },
        qIndex: function() {
            return this.$store.state.questionIndex;
        },
        isLoggedIn() {
            return Object.keys(this.userProfile).length > 1
        },
        examQuestionCount: function() {
            return this.$store.state.exam.length;
        },
        answerCount: function() {
            return Object.keys(this.answers).length;
        },
        submitEnabled: function() {
            return this.examQuestionCount == this.answerCount;
        },
    },
    methods: {
        close() {
            // Store clear exam
            this.$store.dispatch('clearExam')
            this.$emit('closeExam')
        },
        submit() {
            this.submitted = true;

            // Score answers
            this.exam.forEach(question => {
                if (this.answers[question.questionid] == question.correctanswerenglish) {
                    this.results[question.questionid] = 1;
                    this.answeredCorrect += 1
                } else {
                    this.results[question.questionid] = 0;
                }
            });

            if (this.isLoggedIn) {
                // save if logged in - Store results and exam details
                this.$store.dispatch('saveExam', {
                    answers: this.answers,
                    exam: this.exam,
                    results: this.results,
                    created: Date()
                })
            }

            // Update UI
            this.enabled = !this.enabled
            this.showCorrect = !this.showCorrect
        },
    }
}
</script>

<style scoped>
.correct {
    border: 1px solid blue;
    border-radius: 4px;
}
</style>