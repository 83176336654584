<template>
    <v-dialog v-model="dialogFeedback" width="500">
        <template v-slot:activator="{ on, attrs }">
                <v-btn
                v-bind="attrs"
                v-on="on"
                text
                color="white"
                >
                Feedback
                </v-btn>
        </template>

        <v-card>
            <v-card-title class="primary" >
                <span class="white--text">Feedback</span>
            </v-card-title>

            <v-card-text>
                <div class="body-1 mt-4">We'd like to hear from you. You can reach out to us direclty via <a href="mailto:admin@hamtest.ca">email</a> or use this form.</div>
                <v-form
                    ref="form"
                    @submit.prevent="submit"
                    >
                    <v-textarea
                            v-model="feedback"
                            label="Comments"
                            />
                </v-form>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn
                text
                @click="resetForm"
                >
                Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                text
                color="primary"
                type="submit"
                @click="submitFeedback"
                >
                Submit
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialogFeedback: false,
            feedback:''
        }
    },
    computed: {

    },
    methods: {
        submitFeedback() {
            this.$store.dispatch('feedback', this.feedback)
            this.resetForm()
        },
        resetForm () {
            this.feedback = ''
            this.$refs.form.reset()
            this.dialogFeedback = false
        },
    }
}
</script>

<style scoped>

</style>