<template>
  <v-card class="mb-4" :color="isTotal">
    <v-card-title>{{section.title}}</v-card-title>
    <v-card-text>
      <v-row  no-gutters>
        <v-col cols="8">
          <span v-if="section.section !='all'">Section: {{section.section}}</span><br />
          Answered correctly: {{ section.score}}<br />
          Questions attempted:  {{ section.outOf}}
        </v-col>
        <v-col cols="4" right>
          <v-progress-circular
            :rotate="-90"
            :size="80"
            :width="12"
            :value="section.percent"
            color="primary"
          >
            {{ section.percent }}%
        </v-progress-circular>
        </v-col>
      </v-row>

    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        outlined
        @click="onCreateExam"
      >
        {{ buttonText}}
      </v-btn>
    </v-card-actions>
    
  </v-card>
</template>

<script>


export default {
  props: ['section'],
  data() {
    return {
    }
  },
  computed:{
    isTotal () {
        return this.section.title == 'Overall average' ? 'light-green lighten-4' : 'white'
    },
    buttonText () {
      if (this.section.section == 'all'){
        return "All sections test"
      }
      return this.section.section + " section test"
    }
  },
  methods: {
    onCreateExam () {
      this.$emit('createExam', {count:10, section:this.section.section})
    }
  }
}
</script>