<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-alert
                    border="bottom"
                    colored-border
                    color="primary"
                    elevation="2"
                    >
                    <strong>Practice:</strong> generate sets of 10 test questions from the Canadian radio exam bank.
                </v-alert>
                <v-dialog v-model="dialog" hide-overlay scrollable max-width="600px" transition="dialog-bottom-transition">
                    <Exam v-if="showExam" @closeExam="closeExam" />
                </v-dialog>
            </v-col>
    </v-row>
    <v-row >
        <v-col xl="4" lg="4" sm="6" xs="12" md="6" cols="12">
          <SectionProgress :section="progress['Total']" @createExam="createExam" />
          </v-col>
        <v-col xl="4" lg="4" sm="6" xs="12" md="6" cols="12">
          <SectionProgress :section="progress['B-001']" @createExam="createExam" />
          </v-col>
        <v-col xl="4" lg="4" sm="6" xs="12" md="6" cols="12">
          <SectionProgress :section="progress['B-002']" @createExam="createExam" />
        </v-col>
        <v-col xl="4" lg="4" sm="6" xs="12" md="6" cols="12">
          <SectionProgress :section="progress['B-003']" @createExam="createExam" />
          </v-col>
        <v-col xl="4" lg="4" sm="6" xs="12" md="6" cols="12">
          <SectionProgress :section="progress['B-004']" @createExam="createExam" />
          </v-col>
        <v-col xl="4" lg="4" sm="6" xs="12" md="6" cols="12">
          <SectionProgress :section="progress['B-005']" @createExam="createExam" />
        </v-col>
        <v-col xl="4" lg="4" sm="6" xs="12" md="6" cols="12">
          <SectionProgress :section="progress['B-006']" @createExam="createExam" />
          </v-col>
        <v-col xl="4" lg="4" sm="6" xs="12" md="6" cols="12">
          <SectionProgress :section="progress['B-007']" @createExam="createExam" />
          </v-col>
        <v-col xl="4" lg="4" sm="6" xs="12" md="6" cols="12">
          <SectionProgress :section="progress['B-008']" @createExam="createExam" />
        </v-col>
      </v-row>
      <v-row >
        <v-col cols="12">
          <v-card flat>
            <v-data-iterator
              :items="completedExams"
              :items-per-page="itemsPerPage"
              :page.sync="page"
              hide-default-footer
            >
            <template v-slot:header>
                <v-toolbar dark color="light-blue" class="mb-3 rounded-lg elevation-1" >
                    <v-toolbar-title>Past exams</v-toolbar-title>
                    <v-spacer></v-spacer>
    
                    <span class="mr-4  white--text"> Page {{ page }} of {{ numberOfPages }}</span>
                    <v-btn fab dark color="blue darken-3" class="ma-2 elevation-0" @click="formerPage" small>
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn fab dark small color="blue darken-3" class="ma-2  elevation-0" @click="nextPage">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>

            <template v-slot:default="{items}">
                <v-row>
                    <v-col v-for="(item, idx) in items" :key="idx" cols="12" sm="6" md="4" lg="3">
                        <v-card>
                            <v-card-title class="subheading font-weight-bold">
                                {{ item.created | formatDate }}
                                 <v-spacer />
                                <v-chip
                                    class="ml-4"
                                    outlined
                                    >
                                    {{ resultString(item) }}
                                    </v-chip>
                            </v-card-title>
                
                            <v-divider></v-divider>
                
                            <v-timeline align-top dense>
                                <v-timeline-item v-for="(key, index) in examKeys(item.results)" :key="index" :color="item.results[key] == 1 ? 'success' : 'red' " :icon="item.results[key] == 1 ? 'mdi-check' : 'mdi-close'" small>
                                    <div>
                                        {{ key }}
                                    </div>
                                </v-timeline-item>
                            </v-timeline>
                
                            <v-divider></v-divider>
                
                            <v-card-actions>
                                <v-btn color="grey" text @click="deleteExam(item.id)">
                                    <v-icon>mdi-delete</v-icon> </v-btn>
                                <v-spacer />
                                <v-btn color="primary" dark @click="examReviewObj = item; dialogReviewExam = true;">
                                    Review </v-btn>
                                
                                
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </template>

          </v-data-iterator>
          </v-card>
          <v-dialog
            v-model="dialogReviewExam"
            hide-overlay
            scrollable
            max-width="600px"
            transition="dialog-bottom-transition"
          >
          <ExamReview :exam="examReviewObj" @close="dialogReviewExam = false;"></ExamReview>
          </v-dialog>
          <v-dialog
            v-model="deleteDialog"
            width="500"
            >
            <v-card>
                <v-card-title class="headline grey lighten-2">
                Delete test results
                </v-card-title>

                <v-card-text>
                Confirm you wish to delete this test. This will permenantly delete this test.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="confrimDelete"
                >
                    Yes delete test
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
// import { commentsCollection } from '@/firebase'
import moment from 'moment'

export default {
    components: {
        Exam: () =>
            import ( /* webpackChunkName: "Exam" */ "@/components/Exam.vue"),
        ExamReview: () =>
            import ( /* webpackChunkName: "Exam" */ "@/components/ExamReview.vue"),
        SectionProgress: () =>
            import ( /* webpackChunkName: "Exam" */ "@/components/SectionProgress.vue"),
    },
    data() {
        return {
            dialog: false,
            page: 1,
            itemsPerPage: 4,
            dialogReviewExam: false,
            examReviewObj: null,
            deleteDialog: false,
            deleteItem: null,
        }
    },
    computed: {
        numberOfPages() {
            return Math.ceil(this.results.length / this.itemsPerPage)
        },
        progress: function() {
            return this.$store.getters.progress;
        },
        completedExams: function() {
            return this.$store.state.completedExams;
        },
        results: function() {
            return this.$store.getters.results;
        },
        showExam: {
            get() {
                return this.$store.state.exam.length > 0;
            },
            set() {
                // do nothing
            }
        }
    },
    methods: {
        confrimDelete(){
            this.$store.dispatch('deleteExam', this.deleteItem)
            this.deleteDialog = false
            this.deleteItem = null
        },
        deleteExam(key){
            this.deleteDialog = true
            this.deleteItem = key
        },
        examKeys(exam) {
            var questionKeys = Object.keys(exam)
            return questionKeys.filter(key => key !== 'id').sort()
        },
        closeExam() {
            this.dialog = false
            this.$store.dispatch('clearExam')
        },
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1
        },
        createExam(payload) {
            //this.$store.dispatch('fetchPracticeExam')
            //this.$store.dispatch('createQuestions')
            this.$store.dispatch('fetchPracticeExam', payload)
            this.dialog = true
        },
        resultString(exam) {
            var correct = 0
            var questionKeys = Object.keys(exam.results)
            questionKeys.forEach(qkey => {
                correct = correct + exam.results[qkey]
            })
            if (questionKeys.length == 0)
                return "0%"
            var percent = ((correct/questionKeys.length) * 100).toFixed(0);
            return percent + "%"
        },
    },
    filters: {
        formatDate(val) {
            if (!val) { return '-' }
            return moment(val).format("ll")
        }
    }
}
</script>

<style lang="scss" scoped>

</style>